const GLOBAL = {
	screen: {
		md: () => window.matchMedia('(max-width: 1180px)').matches,
	},
	context: {
		/* insert django context here */
	},

	waitImageLoading() {
		const imgs = document.querySelectorAll('img')
		function carregarImagem(img) {
			return new Promise((resolve, reject) => {
				img.onload = () => {
					// console.log(`'carregou', ${img.src || img.srcset}, ${img.scrollHeight}`, img)
					resolve(img)
				}

				img.onerror = (erro) => reject(erro)

				// Verifica se a img já está carregada
				if (img.complete) {
					// console.log('completa', img.src, img.sizes)
					resolve(img)
				}
			})
		}

		imgs?.forEach((img) => {
			const figure = img?.closest('.img-wrapper')
			if (!figure) return
			carregarImagem(img).then((imagem) => {
				figure.classList.add('anime-fade')
			})
		})
	},
}

window.GLOBAL = GLOBAL
